<template>
  <multiselect
    v-model="filterValues"
    :options="options"
    :multiple="true"
    :searchable="false"
    :close-on-select="false"
    :max-height="400"
    :block-keys="['Tab', 'Enter']"
    open-direction="bellow"
    tag-position="bottom"
    select-label=""
    selected-label=""
    deselect-label=""
    group-values="options"
    group-label="label"
    placeholder="Filter by"
    track-by="name"
    label="name"
    class="mr-16"
    @select="onSelect($event)"
    @open="onSaveCurrentFilterValues"
    @close="onSearchCallback"
  >
    <template slot="selection" slot-scope="{ values, isOpen }">
      <span class="multiselect__single" v-if="values.length &amp;&amp; !isOpen"
        >{{ values.length }} options selected</span
      >
    </template>
  </multiselect>
</template>

<script>
import Multiselect from 'vue-multiselect'
import { deepCopy, isObjEqual } from '@/utils/json-parser'

export default {
  name: 'MultipleSelect',

  props: {
    options: {
      type: Array,
      require: true
    },

    value: {
      type: Array,
      require: false
    }
  },

  data() {
    return {
      prevFilterValues: null,
      filterValues: []
    }
  },

  components: {
    Multiselect
  },

  methods: {
    onSearchCallback() {
      if (isObjEqual(this.prevFilterValues, this.filterValues)) return
      this.$emit('filter-callback', deepCopy(this.filterValues))
    },

    removeLastSelected(category) {
      const lastSelectedIndex = this.filterValues.findIndex(item => item.category === category)
      if (lastSelectedIndex < 0) return

      this.filterValues.splice(lastSelectedIndex, 1)
    },

    onSelect(data) {
      if (data.category !== 'status') {
        this.removeLastSelected(data.category)
      }
    },

    onSaveCurrentFilterValues() {
      this.prevFilterValues = deepCopy(this.filterValues)
    },

    initFilterData() {
      if (this.value && this.value.length > 0) {
        if (deepCopy(this.filterValues) === deepCopy(this.value)) return
        this.filterValues = deepCopy(this.value)
      }
    }
  },

  created() {
    this.initFilterData()
  }
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style lang="scss">
.multiselect {
  min-height: unset;
  height: 40px;
  max-width: 240px;
}

.multiselect__content-wrapper {
  min-width: 100%;
  width: 320px;
}

.multiselect__select {
  &:before {
    border-width: 7px 7px 0;
    color: #45414b;
    border-color: #45414b transparent transparent;
  }
}

.multiselect__placeholder {
  color: #a5a2ab;
}

.multiselect__tags {
  font-size: 16px;
  padding-top: 8px;
  padding-bottom: 1px;
  min-height: unset;
  height: 40px;
  border: unset;
}

.multiselect__single {
  white-space: nowrap;
}

.multiselect__tag {
  margin-bottom: 0;
  background-color: #412386;
}

.multiselect__tags-wrap {
  display: block;
  white-space: nowrap;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

.multiselect__tag-icon:after {
  color: #fff;
}

.multiselect__tag-icon:focus,
.multiselect__tag-icon:hover {
  background-color: #8551fc;
}

.multiselect__option.multiselect__option--highlight {
  background: transparent;
  color: #412386;
}

.multiselect__option.multiselect__option--selected {
  color: #412386;
  font-weight: 700;
  background: transparent;
}
</style>
